<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
    <div class="mask">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-white">
          <h4 class="display-4 mb-3">
            <ng-container *ngIf="(!loading); else loadingDetails">
              {{studentDetails.name}}
            </ng-container>
            <ng-template #loadingDetails>
              Loading...
            </ng-template>
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
          <!-- Content -->
          <ng-container *ngIf="!loading; else displaySkeleton">
            Not Loading!
            {{studentDetails | json}}
          </ng-container>
          <ng-template #displaySkeleton>
            <ng-container *ngIf="!error; else displayError">
              <div class="row">
                <app-loading></app-loading>
              </div>
            </ng-container>
            <ng-template #displayError>
              <app-https-error [error]="error"></app-https-error>
            </ng-template>
          </ng-template>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </div>