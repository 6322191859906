import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { LoginComponent } from 'src/app/components/shared/login/login.component';

@Component({
  selector: 'app-link-lti-account',
  templateUrl: './link-lti-account.component.html',
  styleUrls: ['./link-lti-account.component.scss']
})
export class LinkLtiAccountComponent implements OnInit {
  routeSub: Subscription;
  token: string = ''
  userLoggedIn: boolean= false
  modalRef: MdbModalRef<LoginComponent> | null = null;
  authSubscription: Subscription
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: MdbModalService,
    private http: HttpClient,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) { }

  ngOnInit(): void {
    this.checkAuthState()
    console.log('lets go')
    this.extractTokenFromFragment()
  }

  checkAuthState() {
    this.authSubscription = this.afAuth.authState.subscribe((user) => {
      this.userLoggedIn = Boolean(user)
    })
  }

  async loginWithToken(token: string) {
    // Store JWT in memory - don't use firebase to login
    this.token = token
  }
  
  logout() {
    this.afAuth.signOut().then(() => {
    })
  }

  login() {
    this.modalRef = this.modalService.open(LoginComponent, {
      ignoreBackdropClick: true,
    })
  }

  extractTokenFromFragment(): void {
    // Extract the token from the URL fragment
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    const ltiToken = params.get('token');

    if (ltiToken) {
      this.token = ltiToken
      // Save the token in local storage or any other secure storage
      localStorage.setItem('lti-token', ltiToken);

      // Navigate to your desired route
    } else {
      // Handle the case where the token is not present
      console.error('LTI Token not found in URL fragment');
    }
  }
}
