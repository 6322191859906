import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent {
  @Input() height: number = 0
  isDarkMode: boolean = undefined;
  private themeSubscription: Subscription;
  
  constructor(
    private themeToggleService: ThemeToggleService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.checkTheme();
  }


  checkTheme() {
    this.themeSubscription = this.themeToggleService.isDarkMode.subscribe((isDarkMode: any) => {
      if (typeof isDarkMode === 'boolean') {
        this.isDarkMode = isDarkMode;
        this.cdr.detectChanges()
      }
    });
  }

  ngOnDestroy() {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}