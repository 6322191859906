import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {
  lastUpdated = 1684255556238
  constructor(
    private titleService: Title,
    private metaPixelService: MetaPixelService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Terms of Service | Event Odyssey')
    this.metaPixelService.track('ViewContent', {
      content_name: 'Terms of Service',
      content_type: 'legal'
    })
  }

}
