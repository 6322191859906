import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-student-list-item',
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss'
})
export class MyStudentListItemComponent implements OnInit {
  @Input() itemDetails
  constructor(  ) { }

  ngOnInit(): void {

  }


}
