<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
    <div class="mask">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-white">
          <h4 class="display-4 mb-3">Link Account</h4>
        </div>
      </div>
    </div>
  </div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <div class="container-fluid card card-body" style="word-wrap: break-word;">
                <ng-container *ngIf="token; else noLti">
                    <ng-container *ngIf="userLoggedIn; else noEOUserLoggedIn">
                        Lets link your account
                        <div (click)="logout()" class="btn btn-rounded btn-primary">Logout</div>
                    </ng-container>
                    <ng-template #noEOUserLoggedIn>
                        Prompt user to log in
                        <div (click)="login()" class="btn btn-rounded btn-primary">Log in</div>
                    </ng-template>
                </ng-container>
                <ng-template #noLti>
                    <app-https-error [error]="'Unable to parse Canvas/LTI user information.'"></app-https-error>
                </ng-template>
            </div>
        </div>
    </div>