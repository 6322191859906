import { ChangeDetectorRef, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FontLoaderService } from 'src/app/services/font-loader.service';

@Component({
  selector: 'app-slider-content',
  templateUrl: './slider-content.component.html',
  styleUrl: './slider-content.component.scss'
})
export class SliderContentComponent implements OnInit {
  @Input() sliderDetails: any
  @Input() adjustTop: boolean = false
  contentLoaded: boolean = false
  constructor(
    private fontLoader: FontLoaderService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {

  }

  async ngOnInit() {
    if(this.sliderDetails.fonts) {
      await this.sliderDetails.fonts.forEach(async (fontName: string) => {
        await this.fontLoader.loadFont(fontName, ['400', '700']);
      })
    }
    if(this.sliderDetails.css) {
      this.addDynamicStyles(this.sliderDetails.css)
    }
    this.contentLoaded = true
    this.cdr.detectChanges();
  }

  addDynamicStyles(cssContent): void {
    const style = this.renderer.createElement('style');
    style.textContent = cssContent;
    this.renderer.appendChild(document.head, style);
  }

}
