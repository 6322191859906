import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigatorNavigationMenuComponent implements OnInit {
  version: string = ''
  private httpClient: HttpClient;
  constructor(
    public modalRef: MdbModalRef<NavigatorNavigationMenuComponent>,
    handler: HttpBackend,
  ) { 
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.checkVersion()
  }

  checkVersion() {
    this.httpClient.get('./assets/version.txt', {responseType: 'text'}).subscribe((data: any) => {
      if(!(data.includes('Development'))) { data = 'v'+data} else { data = 'Development Version'}
      this.version = data
  })}

}
