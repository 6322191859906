<div  class="d-flex justify-content-center align-items-center h-100 pb-1 pt-3">
    <div class="row w-100 text-center ms-3 me-3">
      <h5>{{content.type}}</h5>
    </div>
  </div>
  <div  class="d-flex justify-content-center align-items-center h-100 pb-1 pt-1">
    <div class="row w-100 ms-3 me-3">
      <div class="col text-start">
        <span class="small">Section</span>
        <h5>{{content.section}}</h5>
      </div>
      <div class="col text-end">
        <span class="small">Row / Seat</span>
        <h5>{{content.row}} / {{content.seat}}</h5>
      </div>
    </div>
  </div>
  <div style="background-color: var(--scanner-darkest)" class="d-flex justify-content-center align-items-center h-100">
    <div class="row w-100 ms-3 me-3">
      <div class="text-center small" type="button" (click)="basicCollapse.toggle()"
        [attr.aria-expanded]="!basicCollapse.collapsed" aria-controls="collapseExample"
        style="display: inline-flex; align-items: center; justify-content: center; padding: 5px; margin: 0 auto;">
        {{ basicCollapse.collapsed ? 'More Details' : 'Less Details' }}
        <i class="fas ms-1"
          [ngClass]="{'fa-angle-down': basicCollapse.collapsed, 'fa-angle-up': !basicCollapse.collapsed}"
          style="font-size: 0.875em; vertical-align: middle; padding: 0; border-radius: 0; width: auto; height: auto; line-height: 1;"></i>
      </div>
      <!--Collapsed content-->
      <div class="mt-0 ms-0 ps-0 me-0 pe-0" id="collapseExample" mdbCollapse #basicCollapse="mdbCollapse">
        <hr  class="hr mt-0 pt-0 mb-0 mb-2" style="background-color: var(--scanner-darker);" />
        <div class="d-flex justify-content-center align-items-center">
          <div class="row w-100">
            <div class="col text-start">
              <span class="small">Purchase Date</span>
              <p class="small">{{(content.created*1000) | date:'MMM d, y, h:mm a'}}</p>
            </div>
            <div class="col text-end">
              <span class="small">Ticket Price</span>
              <p class="small">{{(content.price/100) | currency}}</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="row w-100">
            <div class="col text-start">
              <span class="small">Order ID</span>
              <p class="small">{{content.order}}</p>
            </div>
            <div class="col text-end">
              <span class="small">Status</span>
              <p class="small text-capitalize">{{content.state}}</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center pb-3">
          <div class="row w-100">
            <div class="col text-start">
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="content.userDetails.profilePic"
                  (error)="content.userDetails.profilePic = './assets/user-profile.png'" alt=""
                  style="width: 35px; height: 35px;" class="rounded-circle" />
                <div class="ms-2  mt-0 pt-0 mb-0 pb-0">
                  <div class="small mt-0 pt-0 mb-0 pb-0">Ticket Holder</div>
                  <div class="small mt-0 pt-0 mb-0 pb-0">{{content.userDetails.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
    </div>
    