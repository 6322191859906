import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payout-pdf-page',
  templateUrl: './payout-pdf-page.component.html',
  styleUrls: ['./payout-pdf-page.component.scss']
})
export class PayoutPdfPageComponent implements OnInit {
  payoutDetails: any
  bankDetails: any
  balanceTransactions: any
  communityDetails: any
  loading: boolean = true
  constructor(
    private route: ActivatedRoute,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.fetchDetails()
  }

  fetchDetails() {
    this.route.queryParams.subscribe(params => {
      let payoutId = params['payoutId'];
      let accessCode = params['accessCode'];
      if(payoutId && accessCode) { this.fetchPayoutDetails(payoutId, accessCode) }
    })
  }

  fetchPayoutDetails(payoutId: string, accessCode: string) {
    const callable = this.functions.httpsCallable('getPDFPayoutDetails');
    const obs = callable({
      payoutId: payoutId,
      accessCode: accessCode
    });
    obs.subscribe({
      next: (res) => {
        this.payoutDetails = res.payout
        this.bankDetails = res.bankDetails
        this.communityDetails = res.community
        this.balanceTransactions = res.data
        this.loading = false
      },
      error: (err) => {
        this.loading = false
        console.warn(err)
      },
    })
  }

}
