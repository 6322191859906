// Angular Imports
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';

// QR Code Generation
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';

// MDB Modules
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbStepperModule } from 'mdb-angular-ui-kit/stepper';
import { MdbTimepickerModule } from 'mdb-angular-ui-kit/timepicker';
import { MdbScrollbarModule } from 'mdb-angular-ui-kit/scrollbar';

// Date Picker
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

// Quill
import { QuillModule } from 'ngx-quill'

// Mask
import { NgxMaskModule } from 'ngx-mask'

// File Upload
import { NgxFileDropModule } from 'ngx-file-drop';

// Carousel
import { NgxGlideModule } from 'ngx-glide';

// Directives
import { HoverGifLoaderDirective } from './directives/hover-gif-loader.directive';

// Select
import { NgSelectModule } from '@ng-select/ng-select';

// Components
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './components/shared/login/login.component';
import { ToastComponent } from './components/shared/toast/toast.component';
import { CommunitiesComponent } from './pages/communities/communities.component';
import { MyVideosComponent } from './pages/my-videos/my-videos.component';
import { ListItemComponent } from './components/videos/list-item/list-item.component';
import { VideoPlayerComponent } from './pages/video-player/video-player.component';
import { SidenavComponent } from './components/shared/sidenav/sidenav.component';
import { ListItemLoadingComponent } from './components/videos/list-item-loading/list-item-loading.component';
import { CurrentlyPlayingComponent } from './components/videos/player/currently-playing/currently-playing.component';
import { ChaptersListComponent } from './components/videos/player/chapters-list/chapters-list.component';
import { NotAuthenticatedComponent } from './pages/not-authenticated/not-authenticated.component';
import { CommunityDetailComponent } from './pages/communities/detail/detail.component';
import { CommunityManageComponent } from './pages/communities/manage/manage.component';
import { LandingComponent } from './pages/landing/landing.component';
import { CommunityHeaderComponent } from './components/communities/header/header.component';
import { ManageCommunityVideosComponent } from './components/communities/manage/videos/videos.component';
import { ManageCommunityBillingComponent } from './components/communities/manage/billing/billing.component';
import { ManageCommunityOverviewComponent } from './components/communities/manage/overview/overview.component';
import { StripeRequirementsComponent } from './components/communities/manage/stripe-requirements/stripe-requirements.component';
import { CommunityListItemComponent } from './components/videos/community-list-item/community-list-item.component';
import { CommunityManageListItemComponent } from './components/videos/community-manage-list-item/community-manage-list-item.component';
import { EditVideoComponent } from './components/modals/edit-video/edit-video.component';
import { ManageVideoDetailsComponent } from './components/communities/manage/videos/details/details.component';
import { ManageVideoChaptersComponent } from './components/communities/manage/videos/chapters/chapters.component';
import { ManageVideoPricingComponent } from './components/communities/manage/videos/pricing/pricing.component';
import { CreateProductComponent } from './components/modals/create-product/create-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommunityVideosComponent } from './components/communities/details/videos/videos.component';
import { PurchaseVideoComponent } from './components/modals/purchase-video/purchase-video.component';
import { CheckoutComponent } from './components/billing/checkout/checkout.component';
import { NgxStripeModule } from 'ngx-stripe';
import { AddCardComponent } from './components/billing/add-card/add-card.component';
import { ListCardsComponent } from './components/billing/list-cards/list-cards.component';
import { CardDetailComponent } from './components/billing/card-detail/card-detail.component';
import { SelectProductComponent } from './components/billing/select-product/select-product.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { HttpsErrorComponent } from './components/shared/https-error/https-error.component';
import { PaymentSuccessfulComponent } from './components/billing/payment-successful/payment-successful.component';
import { NoticeComponent } from './components/shared/notice/notice.component';
import { AddChapterComponent } from './components/communities/manage/videos/add-chapter/add-chapter.component';
import { TimecodePipe } from './pipes/timecode.pipe';
import { ElapsedTImePipe } from './pipes/elapsed-time.pipe';
import { ListCardReadersComponent } from './components/communities/manage/list-card-readers/list-card-readers.component';
import { AddCardReaderComponent } from './components/communities/manage/add-card-reader/add-card-reader.component';
import { MembersComponent } from './components/communities/manage/members/members.component';
import { InviteMemberComponent } from './components/communities/manage/invite-member/invite-member.component';
import { SettingsComponent } from './components/communities/manage/settings/settings.component';
import { AngularFireAnalyticsModule, CONFIG, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { PurchasesComponent } from './components/user/purchases/purchases.component';
import { MyStudentsComponent } from './components/user/my-students/my-students.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { ListAccessComponent } from './components/communities/manage/list-access/list-access.component';
import { EnhancedCardDetailComponent } from './components/billing/enhanced-card-detail/enhanced-card-detail.component';
import { PurchaseVideoWithCardReaderComponent } from './components/modals/purchase-video-with-card-reader/purchase-video-with-card-reader.component';
import { CardReaderStatusComponent } from './components/billing/card-reader-status/card-reader-status.component';
import { CardReaderWaitComponent } from './components/billing/card-reader-wait/card-reader-wait.component';
import { CardReaderDeclineComponent } from './components/billing/card-reader-decline/card-reader-decline.component';
import { CardReaderApprovedComponent } from './components/billing/card-reader-approved/card-reader-approved.component';
import { CardReaderUnreachableComponent } from './components/billing/card-reader-unreachable/card-reader-unreachable.component';
import { SelectCustomerComponent } from './components/billing/select-customer/select-customer.component';
import { EditGeneralDetailsComponent } from './components/communities/manage/edit-general-details/edit-general-details.component';
import { AddGuestComponent } from './components/billing/add-guest/add-guest.component';
import { ActiveListComponent } from './components/communities/manage/members/active-list/active-list.component';
import { PendingListComponent } from './components/communities/manage/members/pending-list/pending-list.component';
import { EditCommunityHeaderComponent } from './components/communities/manage/edit-community-header/edit-community-header.component';
import { CampaignListComponent } from './components/communities/manage/members/campaign-list/campaign-list.component';
import { CreateVideoComponent } from './components/communities/manage/videos/create-video/create-video.component';
import { JoinComponent } from './components/communities/join/join.component';
import { JoinCommunityWithCodeComponent } from './components/modals/join-community-with-code/join-community-with-code.component';
import { JoinCommunityByRequestComponent } from './components/modals/join-community-by-request/join-community-by-request.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { SupportComponent } from './pages/support/support.component';
import { SupportDetailComponent } from './components/support/detail/detail.component';
import { ContactComponent } from './components/support/contact/contact.component';
import { CommunityMemberHomeComponent } from './components/communities/member-home/member-home.component';
import { SalesOverviewComponent } from './components/communities/manage/sales/sales.component';
import { CommunityTransactionsListComponent } from './components/communities/manage/sales/transactions-list/transactions-list.component';
import { CommunityPayoutsListComponent } from './components/communities/manage/sales/payouts-list/payouts-list.component';
import { ConfirmActionComponent } from './components/modals/confirm-action/confirm-action.component';
import { PaymentDetailsComponent } from './components/modals/payment-details/payment-details.component';
import { PayoutDetailsComponent } from './components/modals/payout-details/payout-details.component';
import { CommunityManageOverviewChartComponent } from './components/communities/manage/overview/chart/chart.component';
import { CommunityMessagesComponent } from './components/communities/manage/messages/messages.component';
import { InboxComponent } from './components/communities/manage/messages/inbox/inbox.component';
import { SupportInboxComponent } from './components/communities/manage/messages/support/support.component';
import { MessageDetailsComponent } from './components/communities/manage/messages/inbox/message-details/message-details.component';
import { CreatePostComponent } from './components/modals/create-post/create-post.component';
// import { NgXtruncateModule } from 'ngx-truncate';
import { BlogDetailComponent } from './components/communities/member-home/blog-detail/blog-detail.component';
import { ManageGroupsComponent } from './components/communities/manage/members/groups/groups.component';
import { NoResultsComponent } from './components/shared/no-results/no-results.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommunityEventsHomeComponent } from './components/communities/events-home/events-home.component';
import { CreateEventComponent } from './components/modals/create-event/create-event.component';
import { EditPostComponent } from './components/modals/edit-post/edit-post.component';
import { EditEventComponent } from './components/modals/edit-event/edit-event.component';
import { SeatmapViewComponent } from './components/seatmap/seatmap-view/seatmap-view.component';
import { CreateNewMessageComponent } from './components/communities/manage/messages/inbox/create-new-message/create-new-message.component';
import { CreateCampaignComponent } from './components/communities/manage/campaigns/create-campaign/create-campaign.component';
import { CreateGroupComponent } from './components/communities/manage/members/groups/create-group/create-group.component';
import { EditCampaignComponent } from './components/communities/manage/campaigns/edit-campaign/edit-campaign.component';
import { EditGroupComponent } from './components/communities/manage/members/groups/edit-group/edit-group.component';
import { CustomerBillingInfoComponent } from './components/modals/customer-billing-info/customer-billing-info.component';
import { VideoInvoiceItemComponent } from './components/billing/invoice-list/video-invoice-item/video-invoice-item.component';
import { TicketInvoiceItemComponent } from './components/billing/invoice-list/ticket-invoice-item/ticket-invoice-item.component';
import { TicketScannerComponent } from './components/ticket-scanner/ticket-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PurchaseSeatsComponent } from './components/modals/purchase-seats/purchase-seats.component';
import { LearnMoreComponent } from './pages/learn-more/learn-more.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { SuccessScanComponent } from './components/ticket-scanner/success-scan/success-scan.component';
import { ErrorScanComponent } from './components/ticket-scanner/error-scan/error-scan.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { UserSettingsComponent } from './components/modals/user-settings/user-settings.component';
import { LandingHeaderComponent } from './components/landing/landing-header/landing-header.component';
import { TicketPricingCalculatorComponent } from './components/billing/ticket-pricing-calculator/ticket-pricing-calculator.component';
import { VideoPricingCalculatorComponent } from './components/billing/video-pricing-calculator/video-pricing-calculator.component';
import { PricingFaqComponent } from './components/billing/pricing-faq/pricing-faq.component';
import { BillingFaqComponent } from './components/billing/pricing-faq/billing-faq/billing-faq.component';
import { EventTicketFaqComponent } from './components/billing/pricing-faq/event-ticket-faq/event-ticket-faq.component';
import { VideoOnDemandFaqComponent } from './components/billing/pricing-faq/video-on-demand-faq/video-on-demand-faq.component';
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component';
import { ViewTicketComponent } from './pages/view-ticket/view-ticket.component';
import { AppleWalletUnsupportedComponent } from './components/modals/apple-wallet-unsupported/apple-wallet-unsupported.component';
import { SelectSeatPricesComponent } from './components/billing/select-seat-prices/select-seat-prices.component';
import { EditUserGroupsComponent } from './components/modals/edit-user-groups/edit-user-groups.component';
import { RetryVideoFetchTimerComponent } from './components/shared/retry-video-fetch-timer/retry-video-fetch-timer.component';
import { EditEventTimeDateComponent } from './components/modals/create-event/edit-event-time-date/edit-event-time-date.component';
import { EventLocationComponent } from './components/modals/create-event/edit/event-location/event-location.component';
import { AddLocationComponent } from './components/modals/create-event/edit/add-location/add-location.component';
import { ListVenuesComponent } from './components/communities/manage/list-venues/list-venues.component';
import { EditProductComponent } from './components/modals/edit-product/edit-product.component';
import { EditAdminPermissionsComponent } from './components/modals/edit-admin-permissions/edit-admin-permissions.component';
import { SalesTaxComponent } from './components/communities/manage/sales/sales-tax/sales-tax.component';
import { AddTaxProfileComponent } from './components/communities/manage/add-tax-profile/add-tax-profile.component';
import { ValidationIconComponent } from './components/shared/validation-icon/validation-icon.component';
import { AcceptTermsComponent } from './components/modals/accept-terms/accept-terms.component';
import { ViewTermsComponent } from './components/modals/accept-terms/view-terms/view-terms.component';
import { CommunityHomeComponent } from './components/communities/manage/home/home.component';
import { TodosComponent } from './components/communities/manage/todos/todos.component';
import { ListVideographersComponent } from './components/communities/manage/list-videographers/list-videographers.component';
import { ListLinkedCommunitiesComponent } from './components/communities/manage/list-linked-communities/list-linked-communities.component';
import { AcceptAnalyticsComponent } from './components/modals/accept-analytics/accept-analytics.component';
import { LinkCommunityComponent } from './components/modals/link-community/link-community.component';
import { NotificationsComponent } from './components/modals/notifications/notifications.component';
import { NotificationItemComponent } from './components/modals/notifications/notification-item/notification-item.component';
import { ListEnsemblesComponent } from './components/communities/manage/list-ensembles/list-ensembles.component';
import { ProfileComponent } from './components/modals/user-settings/profile/profile.component';
import { EditNotificationsComponent } from './components/modals/user-settings/edit-notifications/edit-notifications.component';
import { EditEnsembleComponent } from './components/modals/edit-ensemble/edit-ensemble.component';
import { AddEnsembleComponent } from './components/modals/add-ensemble/add-ensemble.component';
import { ViewCampaignLinksComponent } from './components/communities/manage/campaigns/view-campaign-links/view-campaign-links.component';
import { DownloadComponent } from './components/videos/download/download.component';
import { WorkInProgressComponent } from './components/shared/work-in-progress/work-in-progress.component';
import { ReceiptPdfPageComponent } from './components/pdf/receipt-pdf-page/receipt-pdf-page.component';
import { PendingPayoutComponent } from './components/communities/manage/pending-payout/pending-payout.component';
import { PayoutPdfPageComponent } from './components/pdf/payout-pdf-page/payout-pdf-page.component';
import { LinkTVComponent } from './pages/link-tv/link-tv.component';
import { SuccessComponent } from './components/shared/success/success.component';
import { PublicCommunityProfileComponent } from './pages/support/public-community-profile/public-community-profile.component';
import { EoSubscriptionOverviewComponent } from './components/manage/eo-subscription-overview/eo-subscription-overview.component';
import { EoSubscriptionGetStartedComponent } from './components/manage/eo-subscription-get-started/eo-subscription-get-started.component';
import { PurchaseEoSubscriptionComponent } from './components/modals/purchase-eo-subscription/purchase-eo-subscription.component';
import { SelectEoSubscriptionComponent } from './components/billing/select-eo-subscription/select-eo-subscription.component';
import { ListAccountsComponent } from './components/communities/manage/list-accounts/list-accounts.component';
import { AddAccountComponent } from './components/communities/manage/add-account/add-account.component';
import { CreateStreamComponent } from './components/communities/manage/streams/create-stream/create-stream.component';
import { PointOfSaleComponent } from './components/communities/manage/point-of-sale/point-of-sale.component';
import { PosNavigationComponent } from './components/communities/manage/point-of-sale/pos-navigation/pos-navigation.component';
import { MainPOSViewComponent } from './components/communities/manage/point-of-sale/main-view/main-view.component';
import { PosControlsComponent } from './components/communities/manage/point-of-sale/main-views/pos-controls/pos-controls.component';
import { MobilePOSViewComponent } from './components/communities/manage/point-of-sale/mobile-view/mobile-view.component';
import { AdditionalInfoComponent } from './components/billing/additional-info/additional-info.component';
import { RedeemComponent } from './pages/redeem/redeem.component';
import { ViewLabelComponent } from './components/pdf/view-label/view-label.component';
import { TrustpilotBadgeComponent } from './components/support/trustpilot-badge/trustpilot-badge.component';
import { RedeemConfirmationComponent } from './components/shared/redeem-confirmation/redeem-confirmation.component';
import { SearchComponent } from './components/shared/search/search.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { NavigatorNavigationComponent } from './components/navigator/navigation/navigation.component';
import { NavigatorNavigationMenuComponent } from './components/navigator/navigation-menu/navigation-menu.component';
import { NavigatorHomeComponent } from './components/navigator/navigator-home/navigator-home.component';
import { NavigatorSettingsComponent } from './components/navigator/navigator-settings/navigator-settings.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AdminComponent } from './pages/admin/admin.component';
import { UsersItemComponent } from './components/shared/search/users-item/users-item.component';
import { ProviderLogoComponent } from './components/shared/provider-logo/provider-logo.component';
import { UserVideoListComponent } from './components/admin/user-video-list/user-video-list.component';
import { UserPurchaseListComponent } from './components/admin/user-purchase-list/user-purchase-list.component';
import { UserMessagesListComponent } from './components/admin/user-messages-list/user-messages-list.component';
import { UserVideoViewListComponent } from './components/admin/user-video-view-list/user-video-view-list.component';
import { VideoViewsItemComponent } from './components/shared/search/video-views-item/video-views-item.component';
import { UserMessagesListItemComponent } from './components/shared/search/user-messages-list-item/user-messages-list-item.component';
import { MyVideosViewComponent } from './pages/my-videos/my-videos-view/my-videos-view.component';
import { LinkLtiAccountComponent } from './pages/link-lti-account/link-lti-account.component';
import { ListStudentsComponent } from './components/communities/manage/list-students/list-students.component';
import { HtmlTruncatePipe } from './pipes/html-truncate.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { ViewMapComponent } from './components/shared/view-map/view-map.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MyStudentListItemComponent } from './components/user/my-students/list-item/list-item.component';
import { MyStudentViewComponent } from './components/user/my-students/student-view/student-view.component';
import { CommunityFundraisersHomeComponent } from './components/communities/fundraisers-home/fundraisers-home.component';
import { FundraiserListItemComponent } from './components/communities/fundraisers-home/list-item/list-item.component';
import { WarningScanComponent } from './components/ticket-scanner/warning-scan/warning-scan.component';
import { TicketDetailsComponent } from './components/ticket-scanner/ticket-details/ticket-details.component';
import { TicketActionsComponent } from './components/ticket-scanner/ticket-actions/ticket-actions.component';
import { CloseButtonComponent } from './components/shared/close-button/close-button.component';
import { TicketProgressComponent } from './components/components/ticket-scanner/progress/progress.component';
import { LogoComponent } from './components/shared/logo/logo.component';
import { ReceiptPageComponent } from './components/pdf/receipt-page/receipt-page.component';
import { TicketPageComponent } from './components/pdf/ticket-page/ticket-page.component';
import { SliderComponent } from './components/shared/slider/slider.component';
import { SliderContentComponent } from './components/shared/slider-content/slider-content.component';
import { FontLoaderService } from './services/font-loader.service';
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    ToastComponent,
    CommunitiesComponent,
    MyVideosComponent,
    ListItemComponent,
    VideoPlayerComponent,
    SidenavComponent,
    ListItemLoadingComponent,
    CurrentlyPlayingComponent,
    ChaptersListComponent,
    NotAuthenticatedComponent,
    CommunityDetailComponent,
    CommunityManageComponent,
    CommunityHeaderComponent,
    LandingComponent,
    LandingHeaderComponent,
    ManageCommunityVideosComponent,
    ManageCommunityBillingComponent,
    ManageCommunityOverviewComponent,
    StripeRequirementsComponent,
    CommunityListItemComponent,
    CommunityManageListItemComponent,
    EditVideoComponent,
    ManageVideoDetailsComponent,
    ManageVideoChaptersComponent,
    ManageVideoPricingComponent,
    CreateProductComponent,
    CommunityVideosComponent,
    PurchaseVideoComponent,
    CheckoutComponent,
    AddCardComponent,
    ListCardsComponent,
    CardDetailComponent,
    SelectProductComponent,
    LoadingComponent,
    HttpsErrorComponent,
    PaymentSuccessfulComponent,
    NoticeComponent,
    AddChapterComponent,
    TimecodePipe,
    ElapsedTImePipe,
    ListCardReadersComponent,
    AddCardReaderComponent,
    MembersComponent,
    InviteMemberComponent,
    SettingsComponent,
    PurchasesComponent,
    MyStudentsComponent,
    TimeAgoPipe,
    ListAccessComponent,
    EnhancedCardDetailComponent,
    PurchaseVideoWithCardReaderComponent,
    CardReaderStatusComponent,
    CardReaderWaitComponent,
    CardReaderDeclineComponent,
    CardReaderApprovedComponent,
    CardReaderUnreachableComponent,
    SelectCustomerComponent,
    EditGeneralDetailsComponent,
    AddGuestComponent,
    TicketActionsComponent,
    LogoComponent,
    ActiveListComponent,
    PendingListComponent,
    EditCommunityHeaderComponent,
    CampaignListComponent,
    CreateVideoComponent,
    JoinComponent,
    JoinCommunityWithCodeComponent,
    TicketDetailsComponent,
    JoinCommunityByRequestComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    SupportComponent,
    SupportDetailComponent,
    ContactComponent,
    CommunityMemberHomeComponent,
    SalesOverviewComponent,
    CommunityTransactionsListComponent,
    CommunityPayoutsListComponent,
    ConfirmActionComponent,
    PaymentDetailsComponent,
    PayoutDetailsComponent,
    CommunityManageOverviewChartComponent,
    CommunityMessagesComponent,
    SupportInboxComponent,
    InboxComponent,
    MessageDetailsComponent,
    CreatePostComponent,
    BlogDetailComponent,
    ManageGroupsComponent,
    NoResultsComponent,
    SliderComponent,
    SliderContentComponent,
    CommunityEventsHomeComponent,
    CommunityFundraisersHomeComponent,
    CreateEventComponent,
    EditPostComponent,
    EditEventComponent,
    SeatmapViewComponent,
    CreateNewMessageComponent,
    CreateCampaignComponent,
    CreateGroupComponent,
    EditCampaignComponent,
    EditGroupComponent,
    CustomerBillingInfoComponent,
    VideoInvoiceItemComponent,
    TicketInvoiceItemComponent,
    TicketScannerComponent,
    PurchaseSeatsComponent,
    LearnMoreComponent,
    PricingComponent,
    SuccessScanComponent,
    ErrorScanComponent,
    WarningScanComponent,
    GetStartedComponent,
    UserSettingsComponent,
    CloseButtonComponent,
    TicketProgressComponent,
    TicketPricingCalculatorComponent,
    VideoPricingCalculatorComponent,
    PricingFaqComponent,
    BillingFaqComponent,
    EventTicketFaqComponent,
    VideoOnDemandFaqComponent,
    MyTicketsComponent,
    ViewTicketComponent,
    AppleWalletUnsupportedComponent,
    SelectSeatPricesComponent,
    EditUserGroupsComponent,
    RetryVideoFetchTimerComponent,
    EditEventTimeDateComponent,
    EventLocationComponent,
    AddLocationComponent,
    ListVenuesComponent,
    EditProductComponent,
    EditAdminPermissionsComponent,
    SalesTaxComponent,
    HoverGifLoaderDirective,
    AddTaxProfileComponent,
    ValidationIconComponent,
    AcceptTermsComponent,
    ViewTermsComponent,
    CommunityHomeComponent,
    TodosComponent,
    ListVideographersComponent,
    ListLinkedCommunitiesComponent,
    AcceptAnalyticsComponent,
    LinkCommunityComponent,
    NotificationsComponent,
    NotificationItemComponent,
    ListEnsemblesComponent,
    ProfileComponent,
    EditNotificationsComponent,
    EditEnsembleComponent,
    AddEnsembleComponent,
    ViewCampaignLinksComponent,
    DownloadComponent,
    WorkInProgressComponent,
    ReceiptPdfPageComponent,
    ReceiptPageComponent,
    PendingPayoutComponent,
    PayoutPdfPageComponent,
    TicketPageComponent,
    LinkTVComponent,
    SuccessComponent,
    PublicCommunityProfileComponent,
    EoSubscriptionOverviewComponent,
    EoSubscriptionGetStartedComponent,
    PurchaseEoSubscriptionComponent,
    SelectEoSubscriptionComponent,
    ListAccountsComponent,
    AddAccountComponent,
    CreateStreamComponent,
    PointOfSaleComponent,
    PosNavigationComponent,
    MainPOSViewComponent,
    PosControlsComponent,
    MobilePOSViewComponent,
    AdditionalInfoComponent,
    RedeemComponent,
    ViewLabelComponent,
    TrustpilotBadgeComponent,
    RedeemConfirmationComponent,
    SearchComponent,
    NavigatorComponent,
    NavigatorNavigationComponent,
    NavigatorNavigationMenuComponent,
    NavigatorHomeComponent,
    NavigatorSettingsComponent,
    AccessDeniedComponent,
    AdminComponent,
    UsersItemComponent,
    ProviderLogoComponent,
    UserVideoListComponent,
    UserPurchaseListComponent,
    UserMessagesListComponent,
    UserVideoViewListComponent,
    VideoViewsItemComponent,
    UserMessagesListItemComponent,
    MyStudentListItemComponent,
    MyStudentViewComponent,
    MyVideosViewComponent,
    LinkLtiAccountComponent,
    ListStudentsComponent,
    ViewMapComponent,
    FundraiserListItemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireAnalyticsModule,
    NgSelectModule,
    NgxGlideModule,
    AppRoutingModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbSidenavModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbTableModule,
    MdbStepperModule,
    MdbTimepickerModule,
    MdbRangeModule,
    MdbSelectModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbScrollbarModule,
    HtmlTruncatePipe,
    MdbTooltipModule,
    MdbDatepickerModule,
    NgxFileDropModule,
    MdbValidationModule,
    MdbNotificationModule,
    GoogleMapsModule,
    NgxStripeModule.forRoot(),
    QuillModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxQrcodeStylingModule,
    ImageCropperModule,
    ZXingScannerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirePerformanceModule,
    AngularFireFunctionsModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [NgxQrcodeStylingModule],
  providers: [
    { provide: REGION, useValue: environment.gcpRegion },
    ScreenTrackingService,
    UserTrackingService,
    FontLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
