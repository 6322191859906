<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Event</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="eventForm">
    <div class="input-group mt-2">
      <div class="input-container">
        <mdb-form-control>
          <input mdbInput [mdbValidate] type="text" formControlName="name" id="name"
            class="form-control form-control-lg" [validateSuccess]="false" placeholder="Name" maxLength="50"/>
          <label mdbLabel class="form-label" for="name">Name</label>
          <div class="form-helper">
            <div class="form-counter">{{ eventForm.controls.name.value.length }} / 50</div>
            <div *ngIf="eventForm.controls.name.value.length < 3" class="form-counter">Minimum 3 characters required
            </div>
            <div *ngIf="eventForm.controls.name.value.length === 50" class="form-counter">Maximum 50 characters allowed
            </div>
          </div>
        </mdb-form-control>
      </div>
      <span class="input-group-text border-0"><app-validation-icon [inputValid]="eventForm.controls.name.invalid"></app-validation-icon>
        <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
    </div>

    <br class="noselect">
    <br *ngIf="eventForm.controls.name.value.length < 3 || eventForm.controls.name.value.length === 50"
      class="noselect">


      <ng-container *ngIf="loadingGroups; else ticketThemesInput">
        <div style="width: 100%; height: 50px;" class="skeleton mt-1"></div>
      </ng-container>
      <ng-template #ticketThemesInput>
        <div class="input-group mt-2">
          <div class="input-container">
            <mdb-form-control>
              <ng-select [validateSuccess]="false" [mdbValidate] mdbInput formControlName="ticketClass" (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false" [items]="ticketThemes" [multiple]="false" [virtualScroll]="true" placeholder="Ticket Theme" bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true" [clearable]="true" [searchable]="true" [virtualScroll]="true" notFoundText="No ticketThemes found" autocomplete="off" required>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                    <div class="">{{item.name}}</div>
                    <div class="text-muted small text-wrap" [innerHTML]="item.description"></div>
                  </div>
                </ng-template>
            </ng-select>
            </mdb-form-control>
          </div>
          <span class="input-group-text border-0 align-self-center" style="margin-top: -20px">
            <app-validation-icon [inputValid]="eventForm.controls.ticketClass.invalid"></app-validation-icon>
            <i class="far fa-question-circle" mdbTooltip="Tooltip" [html]="true" placement="right"></i></span>
        </div>
        <div *ngIf="displaySelectDropdown" style="height: 230px;"></div>
      </ng-template>

    <br class="noselect">
    <div class="row">
      <quill-editor formControlName="message" [style]="editorStyle" [modules]="config"></quill-editor>
    </div>
  </form>
  <br class="noselect"><br class="noselect">
</div>
<div class="modal-footer">
  <button (click)="submit()" [disabled]="eventForm.invalid||savingEvent" type="button" class="btn btn-primary btn-rounded">
    <span *ngIf="savingEvent"><span class="spinner-border spinner-border-sm me-2" role="status"aria-hidden="true"></span> Submitting</span>
    <span *ngIf="!savingEvent">Submit</span>
  </button>
</div>
