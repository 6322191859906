<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel"><span *ngIf="!paymentSuccessful">Purchase </span>{{itemDetails.name}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!paymentSuccessful; else success">
  <div class="modal-body">
    <mdb-stepper #stepper [orientation]="'vertical'" [linear]="true" (stepChange)="stepChange($event)">
      <mdb-step [name]="'Select Product'" [stepForm]="selectProductForm">
        <form [formGroup]="selectProductForm"><input type="hidden" formGroupName="valid"></form>
        <app-select-product [itemDetails]="itemDetails" (isValid)="validateselectProduct($event)"
          (output)="outputselectProduct($event)"></app-select-product>
      </mdb-step>
      <mdb-step [name]="'
      Payment Method'" [stepForm]="selectPaymentForm">
        <form [formGroup]="selectPaymentForm"><input type="hidden" formGroupName="valid"></form>
        <app-list-cards (isValid)="validateselectPayment($event)" (output)="outputselectPayment($event)" (nextStep)="next()"></app-list-cards>
      </mdb-step>
      <mdb-step [name]="'Additional Information'" [stepForm]="additionalInfoForm">
        <form [formGroup]="additionalInfoForm"><input type="hidden" formGroupName="valid"></form>
        <app-additional-info [itemDetails]="itemDetails" (isValid)="validateadditionalInfo($event)" (output)="outputadditionalInfo($event)" (nextStep)="next()"></app-additional-info>
      </mdb-step>
      <mdb-step [name]="'Checkout'" [stepForm]="checkoutForm">
        <form [formGroup]="checkoutForm"><input type="hidden" formGroupName="valid"></form>
        <app-checkout [paymentMethod]="paymentMethod" [itemDetails]="itemDetails" [productDetails]="selectProduct.product" [additionalInfo]="additionalInfo"
          [priceDetails]="selectProduct.price" (isValid)="validatecheckout($event)" (output)="outputcheckout($event)"
          (setStepperToPaymentMethod)="setStepperToPaymentMethod()" (previous)="previous()" [paymentError]="paymentError" [paymentErrorType]="paymentErrorType"></app-checkout>
      </mdb-step>
    </mdb-stepper>
    <div class="modal-footer">
      <button *ngIf="step>1" (click)="previous()"  type="button"
        class="btn btn-grey btn-rounded">Go Back</button>
      <button *ngIf="step===1" (click)="next()" [disabled]="!selectProductValid" type="button"
        class="btn btn-primary btn-rounded">Next</button>
      <button *ngIf="step===2" (click)="next()" [disabled]="!paymentMethod.paymentMethod" type="button"
        class="btn btn-primary btn-rounded">Next</button>
      <button *ngIf="step===3" (click)="next()" [disabled]="!additionalInfoValid" type="button"
        class="btn btn-primary btn-rounded">Next</button>
      <button *ngIf="step>3" (click)="purchase()" [disabled]="purchasing" type="button"
        class="btn btn-primary btn-rounded">
        <span *ngIf="purchasing">Purchasing&nbsp;&nbsp;<span class="spinner-border spinner-border-sm me-2"></span></span>
        <span *ngIf="!purchasing">Purchase</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #success>
  <div class="modal-body">
    <app-payment-successful></app-payment-successful>

    <div class="modal-footer">
      <button (click)="modalRef.close()" type="button"
      class="btn btn-grey btn-rounded">Close</button>
      <a (click)="modalRef.close()"  [routerLink]="'/videos/watch'" [queryParams]="{v: itemDetails.id, purchased: purchasedTime}"
      class="btn btn-primary btn-rounded text-start"><i class="fas fa-play me-2"></i> Watch</a>
    </div>
  </div>

</ng-template>
