<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
    <app-close-button (output)="modalRef.close()"></app-close-button>
</div>
<div class="modal-body">
    <div class="d-grid gap-2 mb-4">
        <button (click)="colorModeToggle()" class="btn btn-primary" type="button" mdbRipple>Dark Mode</button>
    </div>
    <div class="d-grid gap-2">
        <button (click)="restartApp()" class="btn btn-primary" type="button" mdbRipple>Restart App</button>
    </div>
</div>
