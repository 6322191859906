import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initMetaPixel();
  }

  private initMetaPixel() {
    const script = this.document.createElement('script');
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
       fbq('init', '${environment.metaPixelId}');
       fbq('track', 'PageView');
    `;
    this.document.head.appendChild(script);

    const noscript = this.document.createElement('noscript');
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${environment.metaPixelId}&ev=PageView&noscript=1"
    />`;
    this.document.head.appendChild(noscript);
  }

  track(eventName: string, params: any = {}) {
    if (typeof fbq === 'undefined') {
      console.warn('Meta Pixel is not loaded yet.');
      return;
    }
    fbq('track', eventName, params);
  }
}
