<app-navigation  *ngIf="!hideNavigation" [loggedIn]="loggedIn" [userProfile]="userProfile" [sidenav]="sidenav" [environmentName]="environmentName"></app-navigation>
<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" mode="over" scrollContainer="#scroll-container" [transitionDuration]="225">
    <div class="container mt-3 mb-2 ms-2">
      <div class="h-100 d-flex flex-row justify-content-center align-items-center">
        <div class="align-self-start flex-fill">
          <app-logo class="ms-1" [height]="20"></app-logo>
        </div>
        <div class="align-self-center">
          <button type="button" class="btn-close me-2" aria-label="Close" (click)="toggle(sidenav)"></button>
        </div>
      </div>
    </div>
    <ul class="sidenav-menu" id="scroll-container" mdbScrollbar>
      <app-sidenav [globalAdmin]="globalAdmin" [loggedIn]="loggedIn" [userProfile]="userProfile" [sidenav]="sidenav" [joinedCommunities]="joinedCommunities" [joinedCommunitiesLoading]="joinedCommunitiesLoading"  [environmentName]="environmentName" (closeSidenav)="sidenav.close()"></app-sidenav>
    </ul>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>
    <div style="min-height: 600px;" [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </mdb-sidenav-content>
</mdb-sidenav-layout>
<app-footer *ngIf="!hideNavigation"></app-footer>
