import { Component, Input, OnInit, Inject, NgZone, PLATFORM_ID, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { debounce } from 'lodash';

@Component({
  selector: 'app-seatmap-view',
  templateUrl: './seatmap-view.component.html',
  styleUrls: ['./seatmap-view.component.scss']
})
export class SeatmapViewComponent implements OnInit {
  showChart: boolean = true;
  isLoadingChart: boolean = false;
  @Output('selectSeat') output: EventEmitter<any> = new EventEmitter()
  @Input() isLoadingSeatData
  @Input() backgroundImg
  @Input() seatData = []
  private root: am5.Root;

  // Debounced drawMiniMap function
  private debouncedDrawMiniMap: (centerLongitude: number, centerLatitude: number, zoomLevel: number) => void;

  constructor(
    @Inject(PLATFORM_ID) private platformId, private zone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    // Initialize debouncedDrawMiniMap with debounce
    this.debouncedDrawMiniMap = debounce(this.drawMiniMap.bind(this), 150);
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit(): void {
    console.clear = () => {
      this.root = am5.Root.new("seatMapChart");
      this.root._logo.dispose();
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);
    };
  }

  async ngOnChanges() {
    if (!this.isLoadingSeatData && this.seatData.length) {
      this.drawSeatLayout();
    } else {
      this.showChart = false;
    }
  }

  ngAfterViewInit() {
    this.ngOnChanges()
  }

  private async drawSeatLayout() {
    this.browserOnly(async () => {
      if (this.root) { await this.root.dispose(); }

      this.showChart = true;
      this.cdRef.detectChanges();
      this.root = am5.Root.new("seatMapChart");
      this.root._logo.dispose();
      this.root.setThemes([
        am5themes_Animated.new(this.root)
      ]);

      // Create a MapChart
      let chart = this.root.container.children.push(
        am5map.MapChart.new(this.root, {
          projection: am5map.geoMercator(),
          panX: "translateX",
          panY: "translateY",
          zoomLevel: 0.8,
          maxZoomLevel: 10,
          minZoomLevel: 0.5,
          wheelY: "zoom",
          wheelX: "none"
        },
        )
      );


      // Add the background
      let backgroundContainer = chart.children.push(am5.Container.new(this.root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layer: -10
      }));

      let backgroundImage = backgroundContainer.children.push(am5.Picture.new(this.root, {
        width: am5.percent(100),
        height: am5.percent(100),
        src: this.backgroundImg,
        centerX: am5.p50,
        centerY: am5.p50
      }));


      // Create a blank GeoJSON map
      //@ts-expect-error
      chart.geodata = {
        type: "FeatureCollection",
        features: [],
      };

      // Create a MapPolygonSeries
      const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(this.root, {}));

      polygonSeries.mapPolygons.template.setAll({
        tooltipHTML: "{tooltip}",
        cursorOverStyle: "pointer"
      });


      // Define fill colors based on seat state
      const availableColor = am5.color("#00cc00");
      const unavailableColor = am5.color("#a3a3a3");
      const stageColor = am5.color("#1e4a91");
      const selectedColor = am5.color("#ffcc00");

      // Set seat fill colors
      polygonSeries.mapPolygons.template.adapters.add("fill", (fill, target) => {
        const dataItem = target.dataItem;
        if (dataItem) {
          const seat: any = dataItem.dataContext;
          switch (seat.state) {
            case 'available':
              target.set("cursorOverStyle", "pointer");
              return availableColor;
            case 'unavailable':
              target.set("cursorOverStyle", "not-allowed");
              return unavailableColor;
            case 'stage':
              target.set("cursorOverStyle", "not-allowed");
              return stageColor;
            case 'selected':
              target.set("cursorOverStyle", "pointer");
              return selectedColor;
          }
        }
        return fill;
      });

      // Handle seat click events
      polygonSeries.mapPolygons.template.events.on("click", (event) => {
        const seat: any = event.target.dataItem.dataContext;
        switch (seat.state) {
          case 'unavailable':
            break;
          case 'available':
            seat.state = "selected";
            this.output.emit({ type: 'selected', id: seat.id, priceArray: seat.priceArray, row: seat.row, seat: seat.seat, section: seat.section })
            break;
          case 'selected':
            seat.state = "available";
            this.output.emit({ type: 'unselected', id: seat.id, priceArray: seat.priceArray, row: seat.row, seat: seat.seat, section: seat.section })
            break;
        }

        event.target.set("fill", event.target.get("fill"));
      });

      // Create seat data
      const seatData = this.seatData.map((seat) => {
        let tooltip = `Seat: ${seat.row}/${seat.seat}`;
        if ((seat.state !== 'unavailable')&&(seat.priceArray)) { 
          let lowestPrice = seat.priceArray[0].price;
          let highestPrice = seat.priceArray[0].price;
          for (let i = 1; i < seat.priceArray.length; i++) {
            if (seat.priceArray[i].price < lowestPrice) {
              lowestPrice = seat.priceArray[i].price;
            }
            if (seat.priceArray[i].price > highestPrice) {
              highestPrice = seat.priceArray[i].price;
            }
          }
          let formattedLowestPrice = (lowestPrice / 100).toFixed(2);
          let formattedHighestPrice = (highestPrice / 100).toFixed(2);
          if (seat.priceArray.length > 1) {
            tooltip += `<br>Price: $${formattedLowestPrice} - $${formattedHighestPrice}`;
          } else {
            tooltip += `<br>Price: $${formattedLowestPrice}`;
          }
        }
        if (seat.type) {
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [seat.x, seat.y],
                  [seat.x, seat.y + 10],
                  [seat.x + 25, seat.y + 10],
                  [seat.x + 25, seat.y],
                  [seat.x, seat.y],
                ],
              ]
            },
            state: 'stage',
            tooltip: 'Stage'
          };
        } else {
          var coordinates = createSeatCoords(seat.x, seat.y, 0.9, 0.9, (seat.angle || 0));
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                coordinates
              ]
            },
            row: seat.row,
            seat: seat.seat,
            state: seat.state,
            section: seat.section,
            id: seat.id,
            priceArray: seat.priceArray,
            tooltip: tooltip
          };
        }

      });

      polygonSeries.data.setAll(seatData);

      
      // Create a zoom control
      let zoomControl = chart.set("zoomControl", am5map.ZoomControl.new(this.root, {}));
      chart.on("translateX", () => {
        let centerLongitude = chart._settings.translateX;
        let centerLatitude = chart._settings.translateY;
        let zoomLevel = chart.get("zoomLevel");
        updateBackground()
        this.debouncedDrawMiniMap(centerLongitude, centerLatitude, zoomLevel);
      })

      // Create a zoom control
      chart.on("translateY", () => {
        let centerLongitude = chart._settings.translateX;
        let centerLatitude = chart._settings.translateY;
        let zoomLevel = chart.get("zoomLevel");
        updateBackground()
        this.debouncedDrawMiniMap(centerLongitude, centerLatitude, zoomLevel);
      })

      // Update background on pan and zoom

      function updateBackground() {
        const zoom = chart.get("zoomLevel", 1);
        const x = chart.get("translateX", 0);
        const y = chart.get("translateY", 0);

        backgroundContainer.set("x", x);
        backgroundContainer.set("y", y);
        backgroundContainer.set("scale", zoom);
      }

      function createSeatCoords(x, y, width, height, angle = 0) {
        // Convert angle to radians
        const radians = angle * (Math.PI / 180);

        // Calculate the center of the square
        const centerX = x + width / 2;
        const centerY = y + height / 2;

        // Function to rotate a point around the center
        function rotatePoint(px, py, cx, cy, angle) {
          const cos = Math.cos(angle);
          const sin = Math.sin(angle);
          const dx = px - cx;
          const dy = py - cy;
          return [
            cx + dx * cos - dy * sin,
            cy + dx * sin + dy * cos
          ];
        }

        // Generate the coordinates and rotate each point
        const points = [
          [x, y],
          [x, y + height],
          [x + width, y + height],
          [x + width, y],
          [x, y]
        ];

        return points.map(point => rotatePoint(point[0], point[1], centerX, centerY, radians));
      }


      // Set initial chart position and zoom
      //  chart.set("translateX", -(minX + maxX) / 2);
      //  chart.set("translateY", -(minY + maxY) / 2);
      //  chart.set("zoomLevel", 0.8 / Math.max((maxX - minX) / chart.width(), (maxY - minY) / chart.height()));


      // Initial update
      updateBackground();
    });



  }



  drawMiniMap(centerLongitude, centerLatitude, zoomLevel) {

  }
}