<div *ngIf="apiLoaded">
    <google-map [id]="containerId" [mapId]="mapId" [height]="height" width="100%" [center]="{lat: lat, lng: lng}" [zoom]="zoom" [options]="{
      disableDefaultUI: true,
      keyboardShortcuts: false
    }">
      <map-advanced-marker
      #markerElem="mapAdvancedMarker"
      [position]="{ lat: lat, lng: lng }"
      />
    </google-map>
  </div>