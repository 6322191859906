import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
  ticketThemes: any[] = []
  loadingGroups: boolean = false
  ticketThemesLoaded: boolean = false
  displaySelectDropdown: boolean = false

  eventForm: FormGroup
  @Input() communityDetails
  @Input() eventDetails
  savingEvent: boolean = false
  editorStyle = {
    height: '100px'
  };
  config = {
    toolbar: [
      ['bold', 'italic', 'underline',],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ]
  };
  error
  constructor(
    public modalRef: MdbModalRef<EditEventComponent>,
    private functions: AngularFireFunctions,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    console.log(this.eventDetails)
    this.eventForm = new FormGroup({
      name: new FormControl(this.eventDetails.title, Validators.compose([Validators.minLength(3), Validators.required])),
      ticketColor: new FormControl(this.eventDetails.ticketColor, Validators.compose([Validators.minLength(6), Validators.required])),
      message: new FormControl(this.eventDetails.body, Validators.compose([Validators.minLength(3), Validators.required])),
      ticketClass: new FormControl(this.eventDetails.ticketClassId, Validators.compose([Validators.minLength(3), Validators.required])),
      venueId: new FormControl(this.eventDetails.venueId, Validators.compose([Validators.minLength(3), Validators.required])),
      doorsOpenTime: new FormControl(this.eventDetails.doorsOpenTime, Validators.compose([Validators.minLength(3), Validators.required])),
      eventStartTime: new FormControl(this.eventDetails.startTime, Validators.compose([Validators.minLength(3), Validators.required])),
    });
    this.fetchGroups()
  }

  
  fetchGroups() {
    this.loadingGroups = true
    this.backendService.getTicketClasses(this.communityDetails.id).subscribe({
      next: async (res) => {
        this.ticketThemes = res
        this.loadingGroups = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  submit() {
    const name = this.eventForm.controls.name.value
    const message = this.eventForm.controls.message.value
    const ticketColor = this.eventForm.controls.ticketColor.value
    const ticketClass = this.eventForm.controls.ticketClass.value
    const venueId = this.eventForm.controls.venueId.value
    const doorsOpenTime = this.eventForm.controls.doorsOpenTime.value
    const eventStartTime = this.eventForm.controls.eventStartTime.value
    this.savingEvent = true
    const callable = this.functions.httpsCallable('updateCommunityEvent');
    const obs = callable({
      ticketClass: ticketClass,
      communityId: this.communityDetails.id,
      id: this.eventDetails.id,
      venueId: venueId,
      doorsOpenTime: doorsOpenTime,
      eventStartTime: eventStartTime,
      title: name,
      ticketColor: ticketColor,
      body: message
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.savingEvent = false
      },
      error: (err) => {
        console.warn(err)
      },
    })
  }

}
