import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-my-students',
  templateUrl: './my-students.component.html',
  styleUrl: './my-students.component.scss'
})
export class MyStudentsComponent implements OnInit {
  students: []
  loading: boolean = true
  error

  constructor(
    private titleService: Title,
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('My Students | Event Odyssey')
    this.getMyStudents()
  }

  getMyStudents() {
    this.loading = true
    console.log('get students')
    this.backendService.getMyStudents().subscribe({
      next: async (res) => {
        console.log(res)
        this.students = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }
  
}
