import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Maps, MapsLoaderService } from 'src/app/services/maps-loader.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
  locationForm: FormGroup
  searched: boolean = false
  isSaving: boolean = false
  @Input() communityDetails
  @ViewChild('search') public searchElementRef: ElementRef;
  lat = 40.7589;
  lng = -73.9851;

  constructor(
    public modalRef: MdbModalRef<AddLocationComponent>,
    private functions: AngularFireFunctions,
    private mapsLoaderService: MapsLoaderService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.findAdress()
    this.locationForm = new FormGroup({
      name: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      address: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      formattedAddress: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required])),
      utcOffset: new FormControl('', Validators.compose([Validators.minLength(1), Validators.required])),
      notes: new FormControl(''),
      lat: new FormControl(0, Validators.compose([Validators.minLength(0), Validators.required])),
      lng: new FormControl(0, Validators.compose([Validators.minLength(0), Validators.required])),
    });
  }

  ngAfterViewInit(): void {
    this.mapsLoaderService.api.then((maps) => {
      this.initAutocomplete(maps);
      // this.initMap(maps);
    });
  }

  
  initAutocomplete(maps: Maps) {
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.onPlaceChange(autocomplete.getPlace());
      });
    });
  }

  onPlaceChange(place: google.maps.places.PlaceResult) {
    console.log(place)
    if (place.geometry === undefined || place.geometry === null) {
      this.searched = false
      return;
    } else {
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
      this.locationForm.controls.utcOffset.setValue(place.utc_offset_minutes)
      this.locationForm.controls.lat.setValue(this.lat)
      this.locationForm.controls.lng.setValue(this.lng)
      this.locationForm.controls.formattedAddress.setValue(place.formatted_address)
      this.searched = true
    }
    // this.map.setCenter(place.geometry.location);
  }

  handleAddressChange($event) {
  }

  createLocation() {
    let locationDetails = this.locationForm.getRawValue()
    locationDetails.address = locationDetails.formattedAddress
    delete locationDetails.formattedAddress
    this.isSaving = true
    const callable = this.functions.httpsCallable('createCommunityVenue');
    const obs = callable({
      ...locationDetails,
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.modalRef.close('created')
        this.isSaving = false
      },
      error: (err) => {
        this.isSaving = false
        console.warn(err)
      },
    })

  }

  findAdress() {
    // this.mapsAPILoader.load().then(() => {
    //   //@ts-ignore
    //   this.geoCoder = new google.maps.Geocoder;
    //     //@ts-ignore
    //     const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //     autocomplete.addListener("place_changed", () => {
    //       this.ngZone.run(() => {
    //         //@ts-ignore
    //         const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //         if (place.geometry === undefined || place.geometry === null) {
    //           this.searched = false
    //           return;
    //         } else {
    //           this.lat = place.geometry.location.lat();
    //           this.lng = place.geometry.location.lng();
    //           this.locationForm.controls.utcOffset.setValue(place.utc_offset_minutes)
    //           this.locationForm.controls.lat.setValue(this.lat)
    //           this.locationForm.controls.lng.setValue(this.lng)
    //           this.locationForm.controls.formattedAddress.setValue(place.formatted_address)
    //           this.searched = true
    //         }
    //       });
    //     });
    // });
  }

}
