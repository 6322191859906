import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-navigator-settings',
  templateUrl: './navigator-settings.component.html',
  styleUrls: ['./navigator-settings.component.scss']
})
export class NavigatorSettingsComponent implements OnInit {

  constructor(
    public modalRef: MdbModalRef<NavigatorSettingsComponent>,
    private themeToggleService: ThemeToggleService
  ) { }

  ngOnInit(): void {
  }

  restartApp() {
    window.location.reload()
  }
  
  colorModeToggle() {
    this.themeToggleService.toggleTheme()
  }

}
