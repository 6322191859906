import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiLoaded = false;
  private apiLoadingPromise: Promise<void>;

  loadGoogleMapsApi(): Promise<void> {
    if (this.apiLoaded) {
      return Promise.resolve(); // Already loaded, no need to load again
    }

    if (this.apiLoadingPromise) {
      return this.apiLoadingPromise; // Return the existing promise if it's already loading
    }

    // Suppress warnings temporarily
    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (typeof args[0] === 'string' && args[0].includes('Google Maps JavaScript API has been loaded directly without loading=async')) {
        return; // Suppress the specific warning
      }
      originalWarn.apply(console, args); // Allow other warnings
    };

    this.apiLoadingPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=marker&v=beta`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.apiLoaded = true;
        console.warn = originalWarn; // Restore original console.warn after loading
        resolve();
      };

      script.onerror = (error) => {
        console.warn = originalWarn; // Restore original console.warn on error
        reject(error);
      };

      document.head.appendChild(script);
    });

    return this.apiLoadingPromise;
  }
}
