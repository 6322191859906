import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-scan',
  standalone: false,
  templateUrl: './warning-scan.component.html',
  styleUrl: './warning-scan.component.scss'
})
export class WarningScanComponent {
  @Input() content: any

}
