import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-ticket-progress',
  templateUrl: './progress.component.html',
  styleUrl: './progress.component.scss'
})
export class TicketProgressComponent {
  @Input() dontDismiss: false
  @Input() progressWidth
  isDarkMode: boolean = undefined;
  private themeSubscription: Subscription;
  @Output() output = new EventEmitter<any>();
  
  constructor(
    private themeToggleService: ThemeToggleService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.checkTheme();
  }

  
  close() {
    this.output.emit()
  }

  checkTheme() {
    this.themeSubscription = this.themeToggleService.isDarkMode.subscribe((isDarkMode: any) => {
      if (typeof isDarkMode === 'boolean') {
        this.isDarkMode = isDarkMode;
        this.cdr.detectChanges()
      }
    });
  }

  ngOnDestroy() {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
