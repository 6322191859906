import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export type Maps = typeof google.maps;

@Injectable({
  providedIn: 'root'
})
export class MapsLoaderService {

  private static scriptLoaded = false;
  public readonly api = this.load();

  private async load(): Promise<Maps> {
    if (MapsLoaderService.scriptLoaded) {
      return Promise.resolve(google.maps);
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    const callbackName = `cb_` + ((Math.random() * 1e9) >>> 0);
    script.src = this.getScriptSrc(callbackName);

    interface MyWindow { [name: string]: Function; };
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = () => {
        MapsLoaderService.scriptLoaded = true;
        resolve(google.maps);
      };
      script.onerror = reject;
    });
    document.body.appendChild(script);
    return promise.then(() => google.maps);
  }

  private getScriptSrc(callback: string): string {
    interface QueryParams { [key: string]: string; };
    const query: QueryParams = {
      v: '3',
      callback,
      key: environment.googleMapsKey,
      libraries: 'places',
      loading: 'async'
    };
    const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    return `//maps.googleapis.com/maps/api/js?${params}&language=en`;
  }

}
