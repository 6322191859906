import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-scan',
  templateUrl: './error-scan.component.html',
  styleUrls: ['./error-scan.component.scss']
})
export class ErrorScanComponent implements OnInit {
  @Input() content: string
  @Input() error
  constructor() { }

  ngOnInit(): void {
  }

}
