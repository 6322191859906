import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { GoogleMapsService } from 'src/app/services/google-maps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrl: './view-map.component.scss'
})
export class ViewMapComponent {
  apiLoaded: boolean = false;
  @Input() height: string = '400px';
  @Input() id: string = 'google-map';
  mapId: string = environment.defaultMapId
  containerId: string = 'map-container'
  @Input() lat: number;
  @Input() lng: number;
  @Input() zoom: number = 15;

  constructor(
    private googleMapsService: GoogleMapsService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.googleMapsService.loadGoogleMapsApi().then(() => {
      this.apiLoaded = true;
      this.cdr.detectChanges(); // Trigger change detection manually
    }).catch(err => console.error('Google Maps API load error:', err));
  }

}