<div class="progress" [ngStyle]="{'background-color': isDarkMode ? '#08421f' : '#a1dbb8'}" *ngIf="!dontDismiss">
    <div 
      class="progress-bar" 
      [ngStyle]="{'background-color': isDarkMode ? '#14a44d' : '#14a44d'}"
      role="progressbar"
      [style.width.%]="progressWidth"
      aria-valuenow="{{progressWidth}}"
      aria-valuemin="0" 
      aria-valuemax="100">
    </div>
  </div>
