<div class="card">

    <div class="bg-image hover-overlay" mdbRipple rippleColor="light">
        <img [src]="'./assets/tides/1.svg'" class="img-fluid communityBg" />
        <a [routerLink]="'/my-students/'+ itemDetails.id">
            <div class="mask" style="background-color: rgba(0, 0, 0, 0.25);"></div>
        </a>
    </div>
    <div class="card-body">
        <h5 style="margin-bottom: 0px;" class="card-title">{{itemDetails.name}}</h5>
        <p class="card-text">{{itemDetails.description | htmlTruncate:100}}</p>
        <div class="d-flex mb-2">
            <div class="w-100">
                <h6 style="margin-bottom: 0px !important;"><b>{{(itemDetails.raised/100) | currency : 'USD' : 'symbol' :
                        '1.0-0'}}</b></h6>
                <div class="text-small"> of
                    {{(itemDetails.goal/100) | currency : 'USD' : 'symbol' : '1.0-0'}} goal</div>
            </div>
            <div class="flex-shrink-1  text-end">
                <h6 style="margin-bottom: 0px !important;"><b>{{itemDetails.donations}}</b></h6>
                <div class="text-small text-end"> Donations</div>
            </div>
        </div>
        <div class="progress mb-4" style="height: 20px">
            <div class="progress-bar" role="progressbar" [style.width]="progress + '%'" [attr.aria-valuenow]="progress"
                aria-valuemin="0" aria-valuemax="100">
                <span *ngIf="progress>=10">{{ progress | number: '1.0-0' }}%</span>
            </div>
        </div>
        <a (click)="donate()" class="btn btn-block btn-primary btn-rounded">Donate</a>
    </div>
</div>