import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Options } from 'ngx-qrcode-styling';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-ticket-page',
  templateUrl: './ticket-page.component.html',
  styleUrl: './ticket-page.component.scss'
})
export class TicketPageComponent {
  venueDetails: any
  eventDetails: any
  tickets: any[] = []
  lineItems: any
  communityDetails: any
  loading: boolean = true
  public noticeContent: string = ''
  public config: Options = {
    width: 200,
    height: 200,
    dotsOptions: {
      color: "#4d4d4d",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff",

    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService,
    private titleService: Title,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.fetchDetails()
  }

  fetchDetails() {
    this.route.queryParams.subscribe(params => {
      let communityId = params['communityId'];
      let eventId = params['eventId'];
      let orderId = params['orderId'];
      let accessCode = params['accessCode'];
      if(eventId && accessCode && communityId) { this.fetchPaymentDetails(communityId, eventId, orderId, accessCode) }
    })
  }
  
  fetchPaymentDetails(communityId: string, eventId: string, orderId: string, accessCode: string) {
    this.backendService.getTicketDetailsWithAccessCode(communityId, eventId, orderId, accessCode).subscribe({
      next: async (res) => {
        if(res.error) {
          this.loading = false
        } else {
          this.communityDetails = res.communityDetails
          this.tickets = res.tickets
          this.venueDetails = res.venueDetails
          this.eventDetails = res.eventDetails
          this.titleService.setTitle('Tickets for ' + this.eventDetails.name)
          this.loading = false
        }
      },
      error: (err) => {
        this.loading = false
        console.warn(err)
        return
      },
    });

  }

  trackByTicketId(index: number, ticket: any): number {
    return ticket.id;
  }

}
