import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-seat-prices',
  templateUrl: './select-seat-prices.component.html',
  styleUrls: ['./select-seat-prices.component.scss']
})
export class SelectSeatPricesComponent implements OnInit {
  productForm: FormGroup
  @Input() selectedSeats
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  selectedPrice: any = {
    id: ''
  }
  totalPrice: number
  ticketTypeCounts: any[] = []
  products: any[] = []
  loading: boolean = false
  constructor(
    private functions: AngularFireFunctions,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.productForm = this.fb.group({});
   }

  ngOnInit(): void {
    this.selectedSeats.forEach((seat) => {
      const group = this.fb.group({
        type: [seat.priceArray[0].type, Validators.required],
        price: [seat.priceArray[0].price, Validators.required],
      });
      this.productForm.addControl(seat.id, group);
    });
    this.onFormChanges()
  }

  onFormChanges() {
    this.updatePrices()
    this.isValid.emit(this.productForm.valid);
    this.productForm.valueChanges.subscribe(async (val) => {
      await this.updatePrices()
      this.isValid.emit(this.productForm.valid);
    })
  }

  async updatePrices() {
    let selectedSeatsWithPrices = []
    let totalPrice = 0;
    const typeCounts = {};
    this.selectedSeats.forEach((seat) => {
      const seatFormGroup = this.productForm.get(seat.id);
      const typeValue = seatFormGroup.get('type').value;
      const matchingPrice = seat.priceArray.find((price) => price.type === typeValue);
      if (matchingPrice) {
        totalPrice += matchingPrice.price;
        const priceControl = seatFormGroup.get('price');
        if (matchingPrice.price !== priceControl.value) {
          priceControl.setValue(matchingPrice.price);
        }
        selectedSeatsWithPrices.push({
          id: seat.id,
          section: seat.section,
          row: seat.row,
          seat: seat.seat,
          type: typeValue,
          price: priceControl.value
        })
        // Count the types
        if (typeCounts[typeValue]) {
          typeCounts[typeValue]++;
        } else {
          typeCounts[typeValue] = 1;
        }
      }
    });
    this.totalPrice = totalPrice;
    this.ticketTypeCounts = Object.entries(typeCounts).map(([type, count]) => ({ type, count }));
    this.output.emit({totalPrice: totalPrice, ticketTypeCounts: this.ticketTypeCounts, seats: selectedSeatsWithPrices})
  }

  selectPrice(item) {
    this.selectedPrice = item.price
    this.productForm.get('product').setValue(item.product)
    this.productForm.get('price').setValue(item.price)
  }

}
