import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Community } from 'src/app/interfaces/community';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-community-fundraisers-home',
  templateUrl: './fundraisers-home.component.html',
  styleUrl: './fundraisers-home.component.scss'
})
export class CommunityFundraisersHomeComponent implements OnInit {
  @Input() communityDetails: Community
  fundraisers: []
  loading: boolean = true
  error

  constructor(
    private titleService: Title,
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    this.getMyFundraisers()
  }

  getMyFundraisers() {
    this.loading = true
    this.backendService.getCommunityFundraisers(this.communityDetails.id).subscribe({
      next: async (res) => {
        console.log(res)
        this.fundraisers = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }
}
