import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { CreateEventComponent } from '../../modals/create-event/create-event.component';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EditEventComponent } from '../../modals/edit-event/edit-event.component';
import { TicketScannerComponent } from '../../ticket-scanner/ticket-scanner.component';
import { PurchaseSeatsComponent } from '../../modals/purchase-seats/purchase-seats.component';
import { BackendService } from 'src/app/services/backend.service';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';

@Component({
  selector: 'app-community-events-home',
  templateUrl: './events-home.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./events-home.component.scss']
})
export class CommunityEventsHomeComponent implements OnInit {
  ticketScannerModalRef: MdbModalRef<TicketScannerComponent> | null = null;
  currentView: string = 'eventList'
  postDetails: any
  displaySelectDropdown: boolean = false
  venueDetails: any
  modalRef: MdbModalRef<CreateEventComponent> | null = null;
  seatCheckoutModalRef: MdbModalRef<PurchaseSeatsComponent> | null = null;
  editEventModalRef: MdbModalRef<EditEventComponent> | null = null;
  seatmapBackgroundImg: string = ''
  @Input() communityDetails: Community
  @Input() id
  @Input() initialLoad
  seriesEvents = []
  selectedSeats = []
  seatData = []
  events: any[] = []
  loading: boolean = true
  loadingEvent: boolean = false
  isLoadingSeatData: boolean = true
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 24 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;

  limit: number = 5
  error
  errorEvent
  orderDetails
  constructor(
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private backendService: BackendService,
    private toastService: MdbNotificationService
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.getEvents()
  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const eventId = params.id
        if (eventId) {
          this.getEvent(eventId)
          this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
        }
      })
  }

  refreshseatData() {
    this.isLoadingSeatData = true
    this.backendService.getCommunityEventSeatmap(this.communityDetails.id, this.postDetails.id).subscribe({
      next: async (res) => {
        if(res.selectedSeats) {
          res.selectedSeats.forEach((savedSeatReservation: any) => {
            this.selectSeat(savedSeatReservation)
          })
        }
        res.seatmap.push({
          "type": "Stage",
          "x": -12,
          "y": 3
      })
        this.seatData = res.seatmap
        this.seatmapBackgroundImg = res.backgroundImg
        this.isLoadingSeatData = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  getEvents() {
    this.loading = true
    this.backendService.getCommunityEvents(this.communityDetails.id, {
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    }).subscribe({
      next: async (res) => {
        this.events = res
        this.seriesEvents = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }


  getEvent(eventId) {
    this.currentView = 'eventView'
    this.loadingEvent = true
    this.backendService.getCommunityEvent(this.communityDetails.id, eventId).subscribe({
      next: async (res) => {
        console.log(res)
        this.venueDetails = res.venueDetails
        this.postDetails = res
        this.loadingEvent = false
        this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  viewSeatmap() {
    this.refreshseatData()
    this.selectedSeats = []
    this.currentView = 'seatMap'
  }

  checkout() {
    this.seatCheckoutModalRef = this.modalService.open(PurchaseSeatsComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails,
        selectedSeats: this.selectedSeats
      }
    })
    this.seatCheckoutModalRef.onClose.subscribe((data: any) => {
      this.selectedSeats = []
      if (data) {
        switch (data.message) {
          case 'purchaseComplete':
            this.orderDetails = data.orderDetails
            break;
          case 'seatsUnavailable':
            this.errorToast('The seats you selected are no longer available.')
            this.refreshseatData()
            break;
        }
      } else {
        // User cancelled or closed modal - refresh seatmap
        this.refreshseatData()
      }
    });
  }

  
  calculateHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    const length = 1
    // const length = this.communities.length
    return Math.max(minimumHeight, heights[length] || baseHeight) + 'px';
  }

  ticketScanner() {
    this.ticketScannerModalRef = this.modalService.open(TicketScannerComponent, {
      modalClass: 'modal-dialog-scrollable modal-md navigatorModal',
      ignoreBackdropClick: true,
      containerClass: 'navigatorModal',
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.ticketScannerModalRef.onClose.subscribe((data: any) => {
      if (data === 'reset') {
        this.ticketScanner()
      }
    })
  }


  errorToast(message: string) {
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  createEvent() {
    this.modalRef = this.modalService.open(CreateEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.getEvents()
      }
    })
  }

  selectSeat(seatData) {
    switch (seatData.type) {
      case 'selected':
        this.selectedSeats.push({
          section: seatData.section,
          id: seatData.id,
          priceArray: seatData.priceArray,
          row: seatData.row,
          seat: seatData.seat
        })
        break;
      case 'unselected':        
        const index = this.selectedSeats.findIndex(seat => seat.id === seatData.id);
        if (index !== -1) {
          this.selectedSeats.splice(index, 1);
        }
        break;
    }
    this.cdRef.detectChanges()
  }

  editEvent() {
    this.editEventModalRef = this.modalService.open(EditEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails
      }
    })
    this.editEventModalRef.onClose.subscribe((data: any) => {
      if (data === 'created') {
        this.getEvent(this.postDetails.id)
      }
    })
  }


  viewDetails(item: any) {
    this.errorEvent = null
    this.loadingEvent = false
    this.currentView = 'eventView'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + item.id)
    this.getEvent(item.id)
  }


  returnToList() {
    this.currentView = 'eventList'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events')
  }



}
