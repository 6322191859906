import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  lastUpdated = 1684255686289

  constructor(
    private titleService: Title,
    private metaPixelService: MetaPixelService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Privacy Policy | Event Odyssey')
    this.metaPixelService.track('ViewContent', {
      content_name: 'Privacy Policy',
      content_type: 'legal'
    })
  }

}
