<div class="card">
    
    <div class="bg-image hover-overlay" mdbRipple rippleColor="light">
        <img [src]="'./assets/tides/1.svg'"
          class="img-fluid communityBg" />
        <a [routerLink]="'/my-students/'+ itemDetails.id">
          <div class="mask" style="background-color: rgba(0, 0, 0, 0.25);"></div>
        </a>
      </div>
    <div class="card-body">
      <h5 style="margin-bottom: 0px;"  class="card-title">{{itemDetails.name}}</h5>
      <p class="card-text">{{itemDetails.communityName}}
      </p>
      <a [routerLink]="'/my-students/'+ itemDetails.id" class="btn btn-primary btn-rounded text-start">Details</a>
  
      <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
        <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;" class="dropdown-toggle hidden-arrow" role="button"
          mdbDropdownToggle aria-expanded="false">
          <i style="height: 60px; margin-left: 16px; margin-top: 17px" class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
      </span>
        <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <!-- <a (click)="download()" class="dropdown-item pointer">Download</a> -->
            <a class="dropdown-item pointer">View Receipt</a>
          </li>
        </ul>
      </span>
    </div>
  </div>
  