<div style="background-color: var(--scanner-darker);" class="text-center">
  <div class="d-flex justify-content-between align-items-center h-100 p-2">
    <!-- Admit Ticket Button -->
    <div *ngIf="content.status !== 'valid'" (click)="forceValidateTicket()" class="flex-grow-1 me-2">
      <button class="bg-success bg-gradient text-light" style="width: 100%; padding: 10px; border: none; border-radius: 5px;">
        Admit Ticket
      </button>
    </div>
    <!-- Dismiss Button -->
    <div (click)="dismissActionRequired()" [ngClass]="{'flex-grow-1': content.status === 'valid', 'flex-shrink-0': content.status !== 'valid'}">
      <button class="bg-danger bg-gradient text-light" style="width: 100%; padding: 10px; border: none; border-radius: 5px;">
        Dismiss
      </button>
    </div>
  </div>
</div>
