import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { DonateToFundraiserComponent } from 'src/app/components/modals/donate-to-fundraiser/donate-to-fundraiser.component';

@Component({
  selector: 'app-fundraiser-list-item',
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss'
})
export class FundraiserListItemComponent implements OnInit {
  donateToFundraiserModalRef: MdbModalRef<DonateToFundraiserComponent> | null = null;

  @Input() itemDetails
  progress: number = 0
  constructor( 
    private modalService: MdbModalService
   ) { }

  ngOnInit(): void {
    this.progress = Number((this.itemDetails.raised/this.itemDetails.goal)*100)
  }

  donate() {
    this.donateToFundraiserModalRef = this.modalService.open(DonateToFundraiserComponent, {
      modalClass: 'modal-dialog-scrollable modal-md',
      ignoreBackdropClick: true,
      data: {
        itemDetails: this.itemDetails
      }
    })
  }


}
