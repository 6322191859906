import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ticket-actions',
  templateUrl: './ticket-actions.component.html',
  styleUrl: './ticket-actions.component.scss'
})
export class TicketActionsComponent {
  @Input() content
  @Output() outputDismiss = new EventEmitter<any>();
  @Output() outputForceValidate = new EventEmitter<any>();
  
  forceValidateTicket() {
    this.outputForceValidate.emit()
  }

  dismissActionRequired() {
    this.outputDismiss.emit()
  }

}
