import { Component, Input, OnInit, OnDestroy, NgZone } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { NavigatorNavigationMenuComponent } from '../navigation-menu/navigation-menu.component';
import { DatePipe } from '@angular/common';
import { debounce } from 'lodash';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigator-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers: [DatePipe]
})
export class NavigatorNavigationComponent implements OnInit, OnDestroy {
  modalRef: MdbModalRef<NavigatorNavigationComponent> | null = null;
  navigationModalRef: MdbModalRef<NavigatorNavigationMenuComponent> | null = null;
  scannerInitialized: boolean = false
  @Input() batteryInfo
  networkConfig: any
  currentTime: string = '';
  private themeSubscription: Subscription;
  isDarkMode: boolean = false
  chargingIcon = 'fas fa-battery-quarter';
  private chargingIcons = [
    'fas fa-battery-quarter',
    'fas fa-battery-half',
    'fas fa-battery-three-quarters',
    'fas fa-battery-full'
  ];
  private animationId: any;
  private statusIntervalId: any;
  constructor(private modalService: MdbModalService, private themeToggleService: ThemeToggleService,private datePipe: DatePipe, private ngZone: NgZone) { }

  ngOnInit(): void {
    // Set Dark Mode
    // this.themeToggleService.toggleTheme()
    this.checkTheme()
    this.getDeviceStatus();
    this.statusIntervalId = setInterval(() => this.getDeviceStatus(), 3000); // Continuously check battery status
  }

  ngOnDestroy(): void {
    if (this.statusIntervalId) {
      clearInterval(this.statusIntervalId);
    }
    if (this.animationId) {
      clearInterval(this.animationId);
    }
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
  
  checkTheme() {
    this.themeSubscription = this.themeToggleService.isDarkMode.subscribe((isDarkMode: any) => {
      if (typeof isDarkMode === 'boolean') {
        this.isDarkMode = isDarkMode;
      }
    });
  }


  getDeviceStatus() {
    //@ts-ignore
    if (window.Android) {
      //@ts-ignore
      this.networkConfig = JSON.parse(window.Android.getNetworkStatus());
      //@ts-ignore
      let newBatteryInfo = JSON.parse(window.Android.getBatteryStatus());
      this.ngZone.run(() => {
        this.currentTime = this.datePipe.transform(new Date(), 'hh:mm a');
        if (!this.batteryInfo || newBatteryInfo.value !== this.batteryInfo.value || newBatteryInfo.charging !== this.batteryInfo.charging) {
          this.batteryInfo = newBatteryInfo;
        }
      });
    }
  }


  startChargingAnimation() {
    let index = 0;
    if (this.animationId) {
      clearInterval(this.animationId);
    }
    this.animationId = setInterval(() => {
      this.ngZone.run(() => {
        this.chargingIcon = this.chargingIcons[index];
        index = (index + 1) % this.chargingIcons.length;
      });
    }, 700); // Charging animation
  }

  stopChargingAnimation() {
    if (this.animationId) {
      clearInterval(this.animationId);
    }
  }

  getBatteryIcon(value: number, charging: boolean): string {
    let directory = 'battery'
    if(this.isDarkMode) { directory = 'battery-light' }
    const percentage = value * 100;
    let src = ''
    if (percentage >= 90) {
      src = `./assets/device-icons/${directory}/7`;
    } else if (percentage >= 80) {
      src = `./assets/device-icons/${directory}/6`;
    } else if (percentage >= 70) {
      src = `./assets/device-icons/${directory}/5`;
    } else if (percentage >= 60) {
      src = `./assets/device-icons/${directory}/4`;
    } else if (percentage >= 50) {
      src = `./assets/device-icons/${directory}/3`;
    } else if (percentage >= 40) {
      src = `./assets/device-icons/${directory}/2`;
    } else {
      src = `./assets/device-icons/${directory}/1`;
    }
    if (charging) { src += '-charging.svg' } else { src += '.svg' }
    return src
  }

  openMenu() {
    this.navigationModalRef = this.modalService.open(NavigatorNavigationMenuComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen modal-side modal-top-left',
      ignoreBackdropClick: true,
      containerClass: 'left'
    });
  }
}
