import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-scan',
  templateUrl: './success-scan.component.html',
  styleUrls: ['./success-scan.component.scss']
})
export class SuccessScanComponent implements OnInit {
  @Input() content: any

  constructor() { }

  ngOnInit(): void {
  }

}
