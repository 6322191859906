import { Component, OnChanges, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-my-student-view',
  templateUrl: './student-view.component.html',
  styleUrl: './student-view.component.scss'
})
export class MyStudentViewComponent implements OnInit {
  loading: boolean = true
  error
  studentDetails: any
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    const studentId = this.route.snapshot.params['id']
    this.getMyStudent(studentId)
  }


  getMyStudent(studentId: string) {
    this.loading = true
    this.backendService.getStudentDetails(studentId).subscribe({
      next: async (res) => {
        console.log(res)
        this.studentDetails = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }
}
