<div class="warningFill">
  <div class=" mt-5 mb-4">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="row">
        <div #iconWrapper>
          <i class="fas fas fa-exclamation-triangle fa-3x bg-warning bg-gradient text-light"></i>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 mt-3">
      <div class="row mb-3">
        <h5 class="display-5 mb-0 pb-0 fw-bold primaryText">Already Scanned</h5>
        <span class="small secondaryText">{{(content.stateChanged * 1000) | timeAgo}}</span>
      </div>
    </div>
  </div>
</div>