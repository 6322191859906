import { Injectable } from '@angular/core';
import * as WebFont from 'webfontloader';

@Injectable({
  providedIn: 'root'
})
export class FontLoaderService {

  constructor() { }

  loadFont(family: string, weights: string[] = ['400']): Promise<void> {
    return new Promise((resolve, reject) => {
      WebFont.load({
        google: {
          families: [`${family}:${weights.join(',')}`]
        },
        active: () => {
          console.log(`${family} font loaded successfully.`);
          resolve();
        },
        inactive: () => {
          console.log(`Failed to load ${family} font.`);
          reject();
        }
      });
    });
  }
}
